<template>
    <div class="card zp-div-buscador py-3">
        <div class="card-body">
            <form @submit.prevent="filterForm" id="formulario-busqueda">
                <div class="form-group">
                    <h5 class="font-weight-bold">Buscador</h5>
                </div>
                <div class="row justify-content-center">
                    <!--
                    <div class="col-3">
                        <div class="form-group">
                            <v-select-c
                                v-model="form.categoria"
                                placeholder="Tipo de propiedad"
                                :options="categorias"
                                class="zp-estilo-rosa"
                                :reduce="categorias => categorias.nombre"
                                :clearable="false"
                                label="nombre"
                            >
                                <div slot="no-options">
                                    No se encontraron categorias
                                </div>
                            </v-select-c>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <v-select-c
                                v-model="form.estadoPropiedad"
                                placeholder="Estado propiedad"
                                :options="estadosPropiedad"
                                class="zp-estilo-rosa"
                                :disabled="form.categoria === 'Terreno'"
                                :clearable="false"
                            >
                            </v-select-c>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <vue-google-autocomplete
                                id="input-search"
                                classname="form-control"
                                placeholder="Ingrese ciudad"
                                :country="'cl'"
                                types="(regions)"
                                v-model="form.comuna"
                                @focus="onFocus"
                                v-on:placechanged="getAddressData"
                            >
                            </vue-google-autocomplete>
                        </div>
                    </div>
                    -->
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <v-select-c
                                v-model="form.comuna"
                                placeholder="Comuna"
                                :options="comunas"
                                class="zp-estilo-rosa"
                                :reduce="comunas => comunas.nombre"
                                label="nombre"
                            >
                                <div slot="no-options">
                                    No se encontraron comunas
                                </div>
                            </v-select-c>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-block bg-main-color general-btn"
                            disabled
                            v-show="formDisabled"
                        >
                            Buscando...
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary btn-block bg-main-color general-btn"
                            v-show="!formDisabled"
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
//import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Vuetify from 'vuetify'
Vue.use(Vuetify)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select-c', vSelect)

export default {
    name: 'BuscadorHome',
    components: {
        //VueGoogleAutocomplete
    },
    props: ['categorias', 'filtros', 'comunas'],
    data: () => ({
        formDisabled: false,
        form: {
            categoria: 'Departamento',
            comuna: '',
            estadoPropiedad: 'Nuevos'
        },
        estadosPropiedad: ['Nuevos', 'Usados'],
        filtrosUsados: '',
        inputComuna: true
    }),
    mounted () {
        this.filtrosUsados = this.filtros
        if (this.filtrosUsados) {
            this.setInputFilters()
        }
    },
    watch: {
        'form.categoria': function (val) {
            this.form.estadoPropiedad =
                val === 'Terreno' ? 'Nuevos' : this.form.estadoPropiedad
        },
        'form.estadoPropiedad': function (val) {
            const input = document.getElementById('input-search')
            if (val === 'Usados') {
                this.form.comuna = 'Santiago'
                input.style.backgroundColor = '#eee'
                input.style.pointerEvents = 'none'
            } else {
                this.form.comuna = 'Santiago'
                input.style.backgroundColor = '#FFFFFF'
                input.style.pointerEvents = 'all'
            }
        }
    },
    methods: {
        filterForm () {
            //const paramCategoria = this.form.categoria
            //const paramEstadoPropiedad = this.form.estadoPropiedad
            const paramComuna = this.form.comuna ? '/' + this.form.comuna : ''
            const paramTango = this.filtrosUsados?.isTango
                ? '/proyectos-tango'
                : ''
            //const url = `/proyectos/${paramCategoria}/${paramEstadoPropiedad}${paramComuna}`
            const url = `/proyectos${paramTango}${paramComuna}`
            console.log(url)
            this.formDisabled = true
            top.location.href = url
        },
        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        getAddressData (addressData, placeResultData) {
            const comuna = placeResultData.address_components[0].long_name
            this.form.comuna = comuna
        },

        handleError (error) {
            //alert(error)
            console.log(error)
        },
        setInputFilters () {
            //this.form.categoria = this.filtrosUsados.categoria
            //this.form.estadoPropiedad = this.filtrosUsados.usado
            this.form.comuna = this.filtrosUsados.comuna || ''
        },
        onFocus () {
            this.form.comuna = ''
        }
    }
}
</script>

<style scoped>
.zp-slider-container {
    margin-bottom: 20px;
}

button {
    margin: 0 !important;
    padding: 0;
    height: 33px;
}

#input-search {
    height: 2.1rem;
}
</style>
